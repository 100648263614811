<template>
  <v-card class="ma-3">
    <base-loading v-if="loading" />
    <crud-list
      v-else
      v-model="gestores"
      :headers="headers"
      :slots="['item.Celular']"
      :actions="actions"
    >
      <template v-slot:btnCadastro>
        <v-row>
          <v-col>
            <v-btn
              color="green darken-1"
              dark
              class="rounded-lg"
              :loading="loadingSheet"
              @click="exportSheet"
            >
              <v-icon class="mr-2">mdi-file-excel</v-icon>

              <span style="font-size: 16px; font-weight: 600">Exportar</span>
            </v-btn>
          </v-col>
          <v-col>
            <router-link :to="`/${$user.get().role}/gestores/form`">
              <v-btn color="success"> Novo Gestor </v-btn>
            </router-link>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.Celular`]="{ item }">
        {{ item.Celular ? celular(item.Celular) : ' - ' }}
      </template>
    </crud-list>
  </v-card>
</template>

<script>
import { celular } from '../../utils/masks'
import { JsonToExcel } from '../../utils/Worksheet'

export default {
  data() {
    return {
      actions: [
        {
          title: 'Editar gestor',
          color: 'yellow darken-3',
          click: item =>
            this.$router.push({
              path: 'gestores/form',
              query: {
                CodGestor: item.CodGestor,
              },
            }),
          icon: 'mdi-pencil',
        },
        {
          title: 'Inativar gestor',
          color: 'red darken-3',
          click: item => this.deleteItem(item),
          icon: 'mdi-delete',
        },
      ],
      gestores: [],
      loading: true,
      loadingSheet: false,
      headers: [
        {
          text: '',
          sortable: false,
          width: '80px',
          value: 'actions',
          align: 'left',
        },
        {
          text: 'Nome',
          align: 'left',
          sortable: true,
          value: 'Nome',
          width: 'auto',
        },
        {
          text: 'Email',
          align: 'left',
          sortable: true,
          value: 'Email',
          width: 'auto',
        },
        {
          text: 'Telefone',
          align: 'left',
          sortable: true,
          value: 'Celular',
          width: 'auto',
        },
        {
          align: 'left',
          sortable: true,
          text: 'Agroindústria',
          value: 'Agroindustria',
          width: 'auto',
        },
      ],
    }
  },
  created() {
    this.getGestores()
  },
  methods: {
    celular,
    getGestores() {
      this.api.get.gestores().then(data => {
        data = data.map(item => {
          item.Agroindustria = item.Agroindustria.map(
            item => ' ' + item.agroindustria?.pessoajuridica?.NomeFantasia,
          )

          return item
        })

        this.gestores = data
        this.loading = false
      })
    },
    exportSheet() {
      this.loadingSheet = true

      new JsonToExcel(this.gestores, this.headers, 'gestores.xlsx', 'Gestores')

      this.loadingSheet = false
    },
    deleteItem(item) {
      this.Swal.fire({
        icon: 'error',
        title: 'Inativar Gestor',
        text: 'Você tem certeza que deseja inativar este gestor?',
        showCancelButton: true,
        confirmButtonColor: '#f74242',
        confirmButtonText: 'SIM, INATIVAR',
        cancelButtonText: 'NÃO',
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return this.api.delete.gestor(item.CodGestor).then(
            () => {
              return 1
            },
            reject => {
              return reject
            },
          )
        },
        allowOutsideClick: () => !this.Swal.isLoading(),
      }).then(result => {
        if (result.value === 1) {
          this.loading = true
          this.getGestores()
          this.Swal.fire({
            title: 'Sucesso',
            text: 'O gestor foi inativado com sucesso!',
            icon: 'success',
            showConfirmButton: false,
            timer: 2000,
          })
        }
      })
    },
  },
}
</script>
